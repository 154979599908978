/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  /* color: #784e04; */
  color: #242321;
  background-image: url('./images/background.gif');
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
}

/* .App-link {
  color: #61dafb;
} */

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.custom-card {
  background-color: white; 
  box-shadow: 0px 8px 24px rgba(13,13,18,0.04);
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 15px;
  text-align: center;
  position: inherit;

  /* border: gainsboro;
  border-width: 1px;
  border-style: solid; */
}

.header {
  margin: auto;
  font-family: 'Amatic SC', cursive;
  font-size: 50px;
  text-align: center;
  margin-bottom: 35px;
}

.container {
  margin-top: 50px;
}

.profile > img {
  border-radius: 50%;
  position: absolute;
  top: 0;
  box-shadow: 0px 8px 24px rgba(13,13,18,0.16);
  height: 70px;
  width: 70px;
  transform: translate(-50%, -50%);
}

.profile__header > a {
  font-family: 'Didact Gothic', sans-serif;
  font-weight: 700;
  font-size: 22px;
  color: rgb(26, 26, 26);
}

.profile__header > a:hover {
  text-decoration: none;
}

.profile__location {
  margin: 0;
}

.profile {
  /* position: absolute; */
  /* margin: 10px; */
  padding-top: 50px;
  /* width: 100%; */
  text-align: center;
}

.vert__card {
  font-family: 'Didact Gothic', sans-serif;
  font-weight: 200;
  font-size: 22px;
}

.summitLabel > img {
  float: left;
  width: 18px;
  margin-right: 5px;
}

.summitLabel {
  margin-bottom: 5px;
}

.activityDate {
  font-size: 11px;
  color: #666;
  position: absolute;
  right: 0;
  top: 0;
  font-family: sans-serif;
}

.cardBody {
  position: inherit;
}

.activityTitle {
  margin-bottom: 25px;
}

.activityTitle > a {
  color: rgb(26, 26, 26);
}

.activityTitle > a:hover {
  text-decoration: none;
}

.summitHeight {
  font-size: 11px;
  color: rgb(59, 59, 59);
  padding-left: 7px;
}

.summitHeightBigger {
  font-size: 15px;
  color: rgb(59, 59, 59);
  padding-left: 7px;
}

.fadedText {
  font-size: 11px;
  color: rgb(59, 59, 59);
}

.viewSummitButton {
  font-size: 11px;
  color: rgb(66 66 66);
  float: right;
  border: 1px;
  border-style: solid;
  padding: 2px;
  border-color: #bdb8b8;
  border-radius: 3px;
  padding-left: 7px;
  padding-right: 7px;
}

.summitList {
  margin-bottom: 10px;
}

.main-logo {
  width: 300px;
  max-width: 60%;
  margin: auto;
}

.connect-button {
  margin: auto;
  max-width: 50%;
}

.connect-button > img {
  max-width: 100%;
  width: 200px;
  margin-top: 30px;
}

.instruction-image {
  /* width: 350px; */
  width: 65%;
  box-shadow: 0 10px 16px 0 rgba(0,0,0,0.1),0 6px 20px 0 rgba(0,0,0,0.1) !important;
  border-radius: 6px;

}

.instructions {
  margin-top: 100px;
}

.instruction-text {
  font-size: 25px;
  margin-bottom: 40px;
}

.instruction {
  text-align: center;
  margin-bottom: 30px;
}

.user-page-logo {
  width: 200px;
  max-width: 60%;
  margin: auto;
  margin-bottom: 25px;
}

.nav-row {
  text-align: center;
  width: 100%;
  height: 68px;
  margin-bottom: 40px;
}

.flex-wrapper {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.map-container {
  /* position: absolute; */
  /* top: 0;
  bottom: 0;
  left: 0;
  right: 0; */
  height: 500px;
  flex: 1 1 auto;
}

.mapboxgl-canvas:focus {
  outline: none;
}

.navItem {
  width: 80px;
  padding: 10px;
  background: #e2e2e2;
  margin: 10px;
  border-radius: 20px;
  display: inline-block;
}

.navItem {
  color: black;
}

.navItem:hover {
  text-decoration: none;
  color: black;
  background: #8b8b8b;
}

.activeNav {
  background: #9c9c9c;
}